/* eslint-disable react/react-in-jsx-scope */
import "./App.scss";
import Routes from "./routes/routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";

function App() {
    return (
        <div className="App">
            <ToastContainer position={toast.POSITION.TOP_RIGHT} />
            <Routes />
        </div>
    );
}
export default App;
