import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import language_id from "./translations/id/language.json";
import language_en from "./translations/en/language.json";
import language_id_fav from "./translations/id/language-fav.json";
import language_en_fav from "./translations/en/language-fav.json";
import secureStorage from "./config/encrypt";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { IS_FAVORITE_APP } from "./config/constant";

i18next.init({
	interpolation: { escapeValue: false },
	lng: secureStorage.getItem("lang") === "id" ? "id" : "en",
	resources: {
		en: {
			language: (IS_FAVORITE_APP) ? language_en_fav :  language_en,
		},
		id: {
			language: (IS_FAVORITE_APP) ? language_id_fav : language_id,
		},
	},
});

ReactDOM.render(
	<I18nextProvider i18n={i18next}>
		<Provider store={store}>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</Provider>
	</I18nextProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
