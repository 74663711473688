const currencyReducer = (state = "", action) => {
    switch (action.type) {
        case "UPDATE_CURRENCY_CONVERSION":
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export default currencyReducer;