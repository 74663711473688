import { IS_FAVORITE_APP } from "../../config/constant";
const leadReducer = (state='',action)=>{
    switch(action.type){
        case "ADD_LEAD_INFO": 

            let navTab = {
                dealer: false,
                customer:false,
                po:false,
                do:false,
                refund:false,
                car:false,
                payment:false
            }; 
            let activeNum = (action.payload.details && action.payload.details.active_tab !='') ? (IS_FAVORITE_APP && [null,'1'].includes(action.payload.details.active_tab)) ? 2 : action.payload.details.active_tab:1 
            let lead_source = (action.payload.details && action.payload.details.lead_source) ? action.payload.details.lead_source : 'finance'
            if(['refinance','bikerefinance'].includes(lead_source) && +activeNum === 3) navTab.car = true;
            else if(+activeNum === 6) navTab.payment = true;
            else navTab[Object.keys(navTab)[activeNum-1]] = true; 
            return  {
                ...state, 
                leadDetail:action.payload,
                activePage: (action.payload.details) ? (IS_FAVORITE_APP && [null,'1'].includes(action.payload.details.active_tab)) ? 2 : action.payload.details.active_tab:1, 
                detailPage:navTab
            }  

        case "DETAIL_PAGE":
			return {
				...state,
				detailPage: action.payload,
			}; 

		case "UPDATE_LEAD_DETAIL":
			return {
				...state,
				leadDetail: action.payload,
			};   

		case "UPDATE_ACTIVE_PAGE":
			return {
				...state,
				activePage: action.payload,
			};
        
        case "UPDATE_LEAD_INFO":  
            return  {
                ...state, 
                leadDetail:action.payload
            }      
             
        case "SET_PAYMENT_FILTER":
            if (!action.activeTab) {
                return {
                    ...state,
                    paymentFilters: {
                        finance: [],
                        refinance: [],
                    }
                }
            }
            return  {
                ...state, 
                paymentFilters: {
                    ...state.paymentFilters,
                    [action.activeTab]: action.payload
                }
            }
        case "UPDATE_RADIO_CHECK":  
            return  {
                ...state, 
                radio_check:action.payload
            }       
        default:
            return state    
    }
}

export default leadReducer;
