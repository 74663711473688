import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { hasAccessPermission } from "../config/constant";
import secureStorage from "../config/encrypt";

const AccessDenied = lazy(() => import("../view/notfound/AccessDenied"));
const LeadList = lazy(() => import("../view/lead-list/LeadList"));
const FinancierPayment = lazy(() => import("../view/fiancier/FinancierPayment"));
const Header = lazy(() => import("../view/common/Header"));
const Login = lazy(() => import("../view/user/Login"));
const LoginOtp = lazy(() => import("../view/user/LoginOtp"));
const LeadDetail = lazy(() => import("../view/lead-detail/LeadDetail"));
const AdditionalRevenue = lazy(() => import("../view/payment/AdditionalRevenue"));
const OutbountReportTab = lazy(() => import("../view/finance-report/OutbountReportTab"));
const PaymentReport = lazy(() => import("../view/Reports/payment-report/PaymentReport"));
const LoanIdReport = lazy(() => import("../view/Reports/LoanIdReport"));
const RevenueReport = lazy(() => import("../view/Reports/payment-report/RevenueReport"));
const UserRoleManagement = lazy(() => import("../view/user/UserRoleManagement"));
// check for Authorized access
const PageNotFound = lazy(() => import("../view/notfound/PageNotFound"));
const inboudPaymentUpload = lazy(() => import("../view/finance-report/inboudPaymentUpload"));
const LostLeadTab = lazy(() => import("../view/lost-lead/LostLeadTab"));
const InboundReportTab = lazy(() => import("../view/finance-report/InboundReportTab"));
const OtherTransactions = lazy(() => import("../view/other-transactions/OtherTransactions"));
const DealerWalletTab = lazy(() => import("../view/dealer-wallet/DealerWalletTab"));
const ReconcilationTab = lazy(() => import("../view/reconcilation-new/Reconcilation"));

// Dealer Wallet
const DealerTransactionHistory = lazy(() => import("../view/dealer-wallet/DealerTransactionHistory"));
const ReconViewEditTable = lazy(() => import("../view/reconcilation-new/ReconViewEditTable"));
const PendingPaymentConfirmation = lazy(() => import("../view/Reports/pending-payment/PendingPaymentConfirmation"));

// sales-task
const ManageTask = lazy(() => import("../view/sales-management/ManageTask"));
const SalesTaskTable = lazy(() => import("../view/sales-management/SalesTaskTable"));
const MonitoringTask = lazy(() => import("../view/sales-management/monitoring/MonitoringTask"));

// Monthly Payout
const MonthlyPayout = lazy(() => import("../view/monthly-payout/MonthlyPayout"));


// Testing 
const PaymentReportNew = lazy(() => import("../view/Reports/payment-loan-revenue-report/PaymentReport"));
const LoanIdReportNew = lazy(() => import("../view/Reports/payment-loan-revenue-report/LoanIdReport"));
const RevenueReportNew = lazy(() => import("../view/Reports/payment-loan-revenue-report/RevenueReport"));

class CustomRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ht: 500,
            loggedIn: false,
        };
    }

    static getDerivedStateFromProps = (nextProps, state) => {
        const updatedProps = {};
        if (nextProps.loggedIn !== state.loggedIn) {
            updatedProps["loggedIn"] = nextProps.loggedIn;
        }
        return updatedProps;
    };

    componentDidMount = async () => {
        this.setState({ ht: window.innerHeight - 107 });
        window.addEventListener(
            "resize",
            () => {
                this.setState({ ht: window.innerHeight - 107 });
            },
            false,
        );
    };

    render() {
        var loginResponse = secureStorage.getItem("authUserInfo");
        return (
            <Router>
                <Suspense fallback={<div className="loader is-active">Loading...</div>}>
                    {loginResponse ? <Header /> : null}
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/logout" component={LoginOtp} />
                        <Route exact path="/dashboard" component={hasAccessPermission("lead-list") ? LeadList : AccessDenied} />
                        <Route exact path="/lead-list" component={hasAccessPermission("lead-list") ? LeadList : AccessDenied} />
                        <Route path="/lead-detail/:lead_source/:lead_id" component={hasAccessPermission("lead-detail") ? LeadDetail : AccessDenied} />
                        <Route
                            path="/inbound-report/:tab/:subtab"
                            component={hasAccessPermission("finance-report") ? InboundReportTab : AccessDenied}
                        />
                        <Route
                            path="/outbound-report/:tab/:subtab"
                            component={hasAccessPermission("finance-report") ? OutbountReportTab : AccessDenied}
                        />
                        {/* <Route path="/dealer-wallet" component={DealerWalletTab} /> */}
                        <Route path="/wallet/:wallet_type/" component={DealerWalletTab} />

                        <Route path="/loan-id/:page/:query" component={hasAccessPermission("payment-report") ? LoanIdReport : AccessDenied} />

                        {/* <Route path="/loan-id/:page" component={(hasAccessPermission('payment-report')) ? LoanIdReport : AccessDenied} /> */}
                        <Route path="/revenue-report/:query" component={hasAccessPermission("payment-report") ? RevenueReport : AccessDenied} />
                        <Route exact path="/payment-report" component={hasAccessPermission("payment-report") ? PaymentReport : AccessDenied} />
                        <Route exact path="/payment-update" component={hasAccessPermission("payment-update") ? AdditionalRevenue : AccessDenied} />
                        {/* From Elastic purpose */}
                        <Route exact path="/payment-report-new" component={hasAccessPermission("payment-report") ? PaymentReportNew : AccessDenied} />
                        <Route path="/loan-id-new/:page/:query" component={hasAccessPermission("payment-report") ? LoanIdReportNew : AccessDenied} />
                        <Route path="/revenue-report-new/:query" component={hasAccessPermission("payment-report") ? RevenueReportNew : AccessDenied} />

                        <Route
                            exact
                            path="/financier-payment"
                            component={hasAccessPermission("financier-payment") ? FinancierPayment : AccessDenied}
                        />
                        <Route exact path="/user-role" component={hasAccessPermission("user-role") ? UserRoleManagement : AccessDenied} />
                        <Route exact path="/payment-upload" component={inboudPaymentUpload} />
                        <Route exact path="/lost-after-disbursal/:lead_source" component={LostLeadTab} />
                        <Route exact path="/reconcilation/:tab" component={ReconcilationTab} />
                        <Route
                            exact
                            path="/pending-payment-confirmation"
                            component={hasAccessPermission("after-sales") ? PendingPaymentConfirmation : AccessDenied}
                        />
                        <Route
                            exact
                            path="/reconcilation/:statementId/:viewPage"
                            component={hasAccessPermission("reconcillation") ? ReconViewEditTable : AccessDenied}
                        />
                        <Route
                            exact
                            path="/other-transactions"
                            component={hasAccessPermission("other-transactions") ? OtherTransactions : AccessDenied}
                        />

                        <Route path="/transaction-history/:dealer_id/:dealer_name/:wallet_source/:wallet_entity_type" component={DealerTransactionHistory} />
                        <Route path="/monitoring-task" component={MonitoringTask} />
                        <Route path="/manage-task" component={ManageTask} />
                        <Route path="/sales-task-table" component={SalesTaskTable} />
                        <Route path="/monthly-payout/:tabName" component={MonthlyPayout} />
                        {/* <Route path="/dealer-wallet/transaction-history/:query" component={(hasAccessPermission('dealer-wallet')) ? DealerTransactionHistory : DealerTransactionHistory} /> */}
                        <Route path="**" component={PageNotFound} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
        loggedIn: user.loggedIn,
    };
};
export default connect(mapStateToProps, null)(CustomRouter);
//export default Routes;
